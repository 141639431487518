<template>
  <div>
    <AdminAlertForm
      :alert="alert"
      @submit="saveAlert"
      :title="'Modify Park Alert'"
    />
  </div>
</template>

<script>
import AdminAlertService from "@/services/admin/AdminAlertService.js";
import AdminAlertForm from "@/components/admin/AdminAlertForm";
import moment from "moment";

export default {
  name: "AdminAlertModify",
  title: "Admin - Modify Park Alert",
  props: {
    alertId: {
      type: String,
      default: ""
    }
  },
  components: {
    AdminAlertForm
  },
  data() {
    return {
      alert: {}
    };
  },
  methods: {
    async getAlert() {
      const alertService = new AdminAlertService(this.tenantId);
      const response = await alertService.getAlert(this.alertId);
      this.alert = {
        id: response.data.id,
        alertTypeId: response.data.alertTypeId,
        globalAlert: response.data.alertTypeId === 1,
        startDate: moment(response.data.startDate, "YYYY-MM-DDThh:mm:ss.SSSZ")
          .utc()
          .format("MM/DD/YYYY"),
        endDate: moment(response.data.endDate, "YYYY-MM-DDThh:mm:ss.SSSZ")
          .utc()
          .format("MM/DD/YYYY"),
        applyToEmail: response.data.applyToEmail,
        applyToWebsite: response.data.applyToWebsite,
        showAtTop: response.data.showAtTop,
        alertSpots: response.data.alertSpots,
        alertSpotIds: response.data.alertSpots.map(x => x.id),
        message: response.data.message
      };
    },
    saveAlert(request) {
      let alertService = new AdminAlertService(this.tenantId);
      alertService.updateAlert(request).then(response => {
        if (response?.statusCode == "Success") {
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-success",
            message: "Park Alert had been Modified.",
            layer: "admin"
          });
          window.scrollTo(0, 0);
          setTimeout(() => {
            this.$router.push("/admin/park-alert-search").catch(() => {});
          }, 3000);
        } else {
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-danger",
            message: "Something went wrong...",
            layer: "admin"
          });
        }
      });
    }
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    }
  },
  created() {
    this.getAlert();
  }
};
</script>

<style></style>

/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import AlertCommentListResponse from '../model/AlertCommentListResponse';
import AlertCommentResponse from '../model/AlertCommentResponse';
import AlertRequest from '../model/AlertRequest';
import AlertSummaryIEnumerableResponse from '../model/AlertSummaryIEnumerableResponse';
import AlertSummaryResponse from '../model/AlertSummaryResponse';
import AuditHistoryItemIEnumerableResponse from '../model/AuditHistoryItemIEnumerableResponse';
import Response from '../model/Response';

/**
* AdminAlert service.
* @module api/AdminAlertApi
* @version v1
*/
export default class AdminAlertApi {

    /**
    * Constructs a new AdminAlertApi. 
    * @alias module:api/AdminAlertApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * @param {Number} alertId 
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.comment 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AlertCommentResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminAlertAlertIdAddCommentPostWithHttpInfo(alertId, tenantId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'alertId' is set
      if (alertId === undefined || alertId === null) {
        throw new Error("Missing the required parameter 'alertId' when calling v1TenantTenantIdAdminAdminAlertAlertIdAddCommentPost");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminAlertAlertIdAddCommentPost");
      }

      let pathParams = {
        'alertId': alertId,
        'tenantId': tenantId
      };
      let queryParams = {
        'comment': opts['comment']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = AlertCommentResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminAlert/{alertId}/add-comment', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} alertId 
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.comment 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AlertCommentResponse}
     */
    v1TenantTenantIdAdminAdminAlertAlertIdAddCommentPost(alertId, tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminAlertAlertIdAddCommentPostWithHttpInfo(alertId, tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} alertId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AlertCommentListResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminAlertAlertIdCommentsGetWithHttpInfo(alertId, tenantId) {
      let postBody = null;
      // verify the required parameter 'alertId' is set
      if (alertId === undefined || alertId === null) {
        throw new Error("Missing the required parameter 'alertId' when calling v1TenantTenantIdAdminAdminAlertAlertIdCommentsGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminAlertAlertIdCommentsGet");
      }

      let pathParams = {
        'alertId': alertId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = AlertCommentListResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminAlert/{alertId}/comments', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} alertId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AlertCommentListResponse}
     */
    v1TenantTenantIdAdminAdminAlertAlertIdCommentsGet(alertId, tenantId) {
      return this.v1TenantTenantIdAdminAdminAlertAlertIdCommentsGetWithHttpInfo(alertId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} alertId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Response} and HTTP response
     */
    v1TenantTenantIdAdminAdminAlertAlertIdDeleteWithHttpInfo(alertId, tenantId) {
      let postBody = null;
      // verify the required parameter 'alertId' is set
      if (alertId === undefined || alertId === null) {
        throw new Error("Missing the required parameter 'alertId' when calling v1TenantTenantIdAdminAdminAlertAlertIdDelete");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminAlertAlertIdDelete");
      }

      let pathParams = {
        'alertId': alertId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Response;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminAlert/{alertId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} alertId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Response}
     */
    v1TenantTenantIdAdminAdminAlertAlertIdDelete(alertId, tenantId) {
      return this.v1TenantTenantIdAdminAdminAlertAlertIdDeleteWithHttpInfo(alertId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} alertId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AlertSummaryResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminAlertAlertIdGetWithHttpInfo(alertId, tenantId) {
      let postBody = null;
      // verify the required parameter 'alertId' is set
      if (alertId === undefined || alertId === null) {
        throw new Error("Missing the required parameter 'alertId' when calling v1TenantTenantIdAdminAdminAlertAlertIdGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminAlertAlertIdGet");
      }

      let pathParams = {
        'alertId': alertId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = AlertSummaryResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminAlert/{alertId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} alertId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AlertSummaryResponse}
     */
    v1TenantTenantIdAdminAdminAlertAlertIdGet(alertId, tenantId) {
      return this.v1TenantTenantIdAdminAdminAlertAlertIdGetWithHttpInfo(alertId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} alertId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AuditHistoryItemIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminAlertAlertIdHistoryGetWithHttpInfo(alertId, tenantId) {
      let postBody = null;
      // verify the required parameter 'alertId' is set
      if (alertId === undefined || alertId === null) {
        throw new Error("Missing the required parameter 'alertId' when calling v1TenantTenantIdAdminAdminAlertAlertIdHistoryGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminAlertAlertIdHistoryGet");
      }

      let pathParams = {
        'alertId': alertId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = AuditHistoryItemIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminAlert/{alertId}/history', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} alertId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AuditHistoryItemIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminAlertAlertIdHistoryGet(alertId, tenantId) {
      return this.v1TenantTenantIdAdminAdminAlertAlertIdHistoryGetWithHttpInfo(alertId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AlertSummaryIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminAlertGlobalWebGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminAlertGlobalWebGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = AlertSummaryIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminAlert/global/web', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AlertSummaryIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminAlertGlobalWebGet(tenantId) {
      return this.v1TenantTenantIdAdminAdminAlertGlobalWebGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} locationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AlertSummaryIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminAlertLocationLocationIdGetWithHttpInfo(locationId, tenantId) {
      let postBody = null;
      // verify the required parameter 'locationId' is set
      if (locationId === undefined || locationId === null) {
        throw new Error("Missing the required parameter 'locationId' when calling v1TenantTenantIdAdminAdminAlertLocationLocationIdGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminAlertLocationLocationIdGet");
      }

      let pathParams = {
        'locationId': locationId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = AlertSummaryIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminAlert/location/{locationId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} locationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AlertSummaryIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminAlertLocationLocationIdGet(locationId, tenantId) {
      return this.v1TenantTenantIdAdminAdminAlertLocationLocationIdGetWithHttpInfo(locationId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} locationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AlertSummaryIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminAlertLocationLocationIdWebGetWithHttpInfo(locationId, tenantId) {
      let postBody = null;
      // verify the required parameter 'locationId' is set
      if (locationId === undefined || locationId === null) {
        throw new Error("Missing the required parameter 'locationId' when calling v1TenantTenantIdAdminAdminAlertLocationLocationIdWebGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminAlertLocationLocationIdWebGet");
      }

      let pathParams = {
        'locationId': locationId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = AlertSummaryIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminAlert/location/{locationId}/web', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} locationId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AlertSummaryIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminAlertLocationLocationIdWebGet(locationId, tenantId) {
      return this.v1TenantTenantIdAdminAdminAlertLocationLocationIdWebGetWithHttpInfo(locationId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/AlertRequest} opts.alertRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Response} and HTTP response
     */
    v1TenantTenantIdAdminAdminAlertPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['alertRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminAlertPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Response;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminAlert', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/AlertRequest} opts.alertRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Response}
     */
    v1TenantTenantIdAdminAdminAlertPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminAlertPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/AlertRequest} opts.alertRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Response} and HTTP response
     */
    v1TenantTenantIdAdminAdminAlertPutWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['alertRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminAlertPut");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Response;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminAlert', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/AlertRequest} opts.alertRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Response}
     */
    v1TenantTenantIdAdminAdminAlertPut(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminAlertPutWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}

/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import AlertComment from './AlertComment';
import AlertSpotSummary from './AlertSpotSummary';

/**
 * The AlertSummary model module.
 * @module model/AlertSummary
 * @version v1
 */
class AlertSummary {
    /**
     * Constructs a new <code>AlertSummary</code>.
     * @alias module:model/AlertSummary
     */
    constructor() { 
        
        AlertSummary.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>AlertSummary</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/AlertSummary} obj Optional instance to populate.
     * @return {module:model/AlertSummary} The populated <code>AlertSummary</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AlertSummary();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('tenantId')) {
                obj['tenantId'] = ApiClient.convertToType(data['tenantId'], 'String');
            }
            if (data.hasOwnProperty('startDate')) {
                obj['startDate'] = ApiClient.convertToType(data['startDate'], 'Date');
            }
            if (data.hasOwnProperty('endDate')) {
                obj['endDate'] = ApiClient.convertToType(data['endDate'], 'Date');
            }
            if (data.hasOwnProperty('alertTypeId')) {
                obj['alertTypeId'] = ApiClient.convertToType(data['alertTypeId'], 'Number');
            }
            if (data.hasOwnProperty('alertTypeName')) {
                obj['alertTypeName'] = ApiClient.convertToType(data['alertTypeName'], 'String');
            }
            if (data.hasOwnProperty('message')) {
                obj['message'] = ApiClient.convertToType(data['message'], 'String');
            }
            if (data.hasOwnProperty('applyToEmail')) {
                obj['applyToEmail'] = ApiClient.convertToType(data['applyToEmail'], 'Boolean');
            }
            if (data.hasOwnProperty('applyToWebsite')) {
                obj['applyToWebsite'] = ApiClient.convertToType(data['applyToWebsite'], 'Boolean');
            }
            if (data.hasOwnProperty('alertSpots')) {
                obj['alertSpots'] = ApiClient.convertToType(data['alertSpots'], [AlertSpotSummary]);
            }
            if (data.hasOwnProperty('alertComments')) {
                obj['alertComments'] = ApiClient.convertToType(data['alertComments'], [AlertComment]);
            }
            if (data.hasOwnProperty('dateLastModified')) {
                obj['dateLastModified'] = ApiClient.convertToType(data['dateLastModified'], 'Date');
            }
            if (data.hasOwnProperty('showAtTop')) {
                obj['showAtTop'] = ApiClient.convertToType(data['showAtTop'], 'Boolean');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
AlertSummary.prototype['id'] = undefined;

/**
 * @member {String} tenantId
 */
AlertSummary.prototype['tenantId'] = undefined;

/**
 * @member {Date} startDate
 */
AlertSummary.prototype['startDate'] = undefined;

/**
 * @member {Date} endDate
 */
AlertSummary.prototype['endDate'] = undefined;

/**
 * @member {Number} alertTypeId
 */
AlertSummary.prototype['alertTypeId'] = undefined;

/**
 * @member {String} alertTypeName
 */
AlertSummary.prototype['alertTypeName'] = undefined;

/**
 * @member {String} message
 */
AlertSummary.prototype['message'] = undefined;

/**
 * @member {Boolean} applyToEmail
 */
AlertSummary.prototype['applyToEmail'] = undefined;

/**
 * @member {Boolean} applyToWebsite
 */
AlertSummary.prototype['applyToWebsite'] = undefined;

/**
 * @member {Array.<module:model/AlertSpotSummary>} alertSpots
 */
AlertSummary.prototype['alertSpots'] = undefined;

/**
 * @member {Array.<module:model/AlertComment>} alertComments
 */
AlertSummary.prototype['alertComments'] = undefined;

/**
 * @member {Date} dateLastModified
 */
AlertSummary.prototype['dateLastModified'] = undefined;

/**
 * @member {Boolean} showAtTop
 */
AlertSummary.prototype['showAtTop'] = undefined;






export default AlertSummary;


<template>
  <div>
    <h3>
      Park Alerts
      <hr />
    </h3>
    <div class="card" v-if="!loading">
      <div class="card-header">
        <div class="card-title mb-0">{{ title }}</div>
      </div>
      <div class="card-body">
        <ValidationObserver ref="parkAlertForm">
          <FormErrorAlert
            v-if="errors.length > 0"
            :errors="errors"
            :formRef="this.$refs.parkAlertForm"
          />
          <form>
            <div class="row mb-2">
              <div class="col-md-2 col-sm-12">
                <TextInput
                  name="Reference ID"
                  id="refId"
                  :disabled="true"
                  v-model="referenceId"
                />
              </div>
              <div class="col-md-4 col-sm-12">
                <DateInput
                  vid="startDate"
                  rules="required|isDate"
                  name="Alert From"
                  id="startDate"
                  v-model="parentAlert.startDate"
                />
                <small class="form-text text-muted mb-2"
                  >Start (effective) date of the alert.</small
                >
              </div>
              <div class="col-md-4 col-sm-12">
                <DateInput
                  vid="endDate"
                  rules="required|isDate|departureDateMinusOne:@startDate"
                  name="Alert To"
                  :dateDisabledFn="minDepartureDate"
                  :initialDate="initialDate"
                  id="endDate"
                  v-model="parentAlert.endDate"
                />
                <small class="form-text text-muted mb-2"
                  >End date of the alert</small
                >
              </div>
              <div class="col-md-2 col-sm-12">
                <div class="d-flex align-items-center h-100">
                  <div v-if="hasGlobalPermission">
                    <input
                      type="checkbox"
                      class="mr-2"
                      id="globalAlert"
                      v-model="parentAlert.globalAlert"
                    />
                    <label class="form-check-label" for="globalAlert"
                      >Global Alert</label
                    >
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-md-4 col-sm-12">
                <ValidationProvider
                  name="Regions"
                  v-slot="{ errors, ariaInput, ariaMsg }"
                >
                  <label :class="{ error: errors[0] }" for="closureRegions">
                    Regions
                  </label>
                  <multiselect
                    v-model="selectedRegions"
                    label="name"
                    track-by="id"
                    :multiple="true"
                    id="closureRegions"
                    :clear-on-select="false"
                    :preserve-search="true"
                    open-direction="bottom"
                    placeholder="Select Region(s)"
                    :options="regions"
                    v-bind="ariaInput"
                    @select="filterParksAdd"
                    @remove="filterParksRemove"
                    :disabled="
                      parentAlert.globalAlert === true || !hasParkSpotPermission
                    "
                    ref="closureRegions"
                  >
                    <template slot="tag" slot-scope="{ option, remove }">
                      <span class="multiselect__tag" :key="option.id">
                        <span>{{ option.name }}</span>
                        <i
                          tabindex="1"
                          @keypress.enter.prevent="remove(option)"
                          @mousedown.prevent="remove(option)"
                          class="multiselect__tag-icon"
                        ></i>
                      </span>
                    </template>
                  </multiselect>
                  <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                    <li
                      v-for="(error, index) in errors"
                      :key="index"
                      class="error"
                      v-bind="ariaMsg"
                    >
                      {{ error }}
                    </li>
                  </ul>
                </ValidationProvider>
                <small class="form-text text-muted mb-2">
                  Selecting a region will select all parks associated with that
                  region. Removing a region will remove all parks of that region
                  from the list of selected parks.
                </small>
                <button
                  type="button"
                  class="btn btn-primary mr-2 my-1"
                  @click="selectAllParksAndRegions"
                  :disabled="
                    parentAlert.globalAlert === true || !hasParkSpotPermission
                  "
                >
                  SELECT ALL
                </button>
                <button
                  type="button"
                  class="btn btn-primary mr-2 my-1"
                  @click="deselectAllParksAndRegions"
                  :disabled="
                    parentAlert.globalAlert === true || !hasParkSpotPermission
                  "
                >
                  DESELECT ALL
                </button>
              </div>
              <div class="col-md-8 col-sm-12">
                <ValidationProvider
                  name="Parks"
                  v-slot="{ errors, ariaInput, ariaMsg }"
                  :rules="
                    `${parentAlert.globalAlert === true ? '' : 'required'}`
                  "
                >
                  <label :class="{ error: errors[0] }" for="closureParks">
                    Parks
                    <span v-if="parentAlert.globalAlert === false" class="error"
                      >*</span
                    >
                  </label>
                  <multiselect
                    v-model="selectedParks"
                    label="name"
                    track-by="id"
                    :multiple="true"
                    id="closureParks"
                    ref="closureParks"
                    :clear-on-select="false"
                    :preserve-search="true"
                    open-direction="bottom"
                    placeholder="Select Park(s)"
                    @input="openedSpots"
                    @open="openedParks"
                    :loading="loadingParks"
                    :options="parks"
                    v-bind="ariaInput"
                    :disabled="
                      parentAlert.globalAlert === true || !hasParkSpotPermission
                    "
                  >
                    <template slot="tag" slot-scope="{ option, remove }">
                      <span class="multiselect__tag" :key="option.id">
                        <span>{{ option.name }}</span>
                        <i
                          tabindex="1"
                          @keypress.enter.prevent="remove(option)"
                          @mousedown.prevent="remove(option)"
                          class="multiselect__tag-icon"
                        ></i>
                      </span>
                    </template>
                  </multiselect>
                  <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                    <li
                      v-for="(error, index) in errors"
                      :key="index"
                      class="error"
                      v-bind="ariaMsg"
                    >
                      {{ error }}
                    </li>
                  </ul>
                </ValidationProvider>
                <small class="form-text text-muted mb-2">
                  Selecting a park will load all spots available for that park
                  below. Removing a park will remove all spots associated with
                  that park from the list of available spots.
                </small>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-md-4 col-sm-12">
                <ValidationProvider
                  name="Spot Types"
                  v-slot="{ errors, ariaInput, ariaMsg }"
                >
                  <label :class="{ error: errors[0] }" for="closureSpotTypes">
                    Spot Types
                  </label>
                  <multiselect
                    v-model="selectedSpotTypes"
                    label="name"
                    track-by="id"
                    :multiple="true"
                    id="closureSpotTypes"
                    ref="closureSpotTypes"
                    :clear-on-select="false"
                    :preserve-search="true"
                    open-direction="bottom"
                    placeholder="Select Spot Type(s)"
                    :options="spotTypes"
                    v-bind="ariaInput"
                    :disabled="parkNotSelected || !hasParkSpotPermission"
                    @open="openedSpotTypes"
                    @select="filterSpotsAdd"
                    @remove="filterSpotsRemove"
                  >
                    <template slot="tag" slot-scope="{ option, remove }">
                      <span class="multiselect__tag" :key="option.id">
                        <span>{{ option.name }}</span>
                        <i
                          tabindex="1"
                          @keypress.enter.prevent="remove(option)"
                          @mousedown.prevent="remove(option)"
                          class="multiselect__tag-icon"
                        ></i>
                      </span>
                    </template>
                  </multiselect>
                  <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                    <li
                      v-for="(error, index) in errors"
                      :key="index"
                      class="error"
                      v-bind="ariaMsg"
                    >
                      {{ error }}
                    </li>
                  </ul>
                </ValidationProvider>
                <small class="form-text text-muted mb-2">
                  Selecting a spot type will select all spots associated with
                  that spot type. Removing a spot type will remove spots
                  associated with that spot type from the list of selected
                  spots.
                </small>
                <button
                  type="button"
                  class="btn btn-primary mr-2 my-1"
                  @click="selectAllSpotsAndSpotTypes"
                  :disabled="parkNotSelected || !hasParkSpotPermission"
                >
                  SELECT ALL
                </button>
                <button
                  type="button"
                  class="btn btn-primary mr-2 my-1"
                  @click="deselectAllSpotsAndSpotTypes"
                  :disabled="parkNotSelected || !hasParkSpotPermission"
                >
                  DESELECT ALL
                </button>
              </div>
              <div class="col-md-8 col-sm-12">
                <div class="row">
                  <div class="col-12">
                    <label for="filterInput"
                      >{{ spots.filter(x => x.isSelected).length }} of
                      {{ spots.length }} total spots selected</label
                    >
                    <div class="d-flex">
                      <div class="grow">
                        <b-form-input
                          v-model="filter"
                          type="search"
                          id="filterInput"
                          placeholder="Search Spots..."
                          class="mb-3"
                          :disabled="parkNotSelected || !hasParkSpotPermission"
                        ></b-form-input>
                      </div>
                      <div>
                        <button
                          type="button"
                          @click="filter = ''"
                          class="btn btn-primary"
                          :disabled="parkNotSelected || !hasParkSpotPermission"
                        >
                          <i class="fas fa-times"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-12"
                    style="max-height: 300px; overflow-y: hidden"
                  >
                    <b-table
                      ref="table"
                      id="spotTable"
                      striped
                      :fields="fields"
                      :items="spots"
                      :filter="filter"
                      small
                      outlined
                      empty-text="No Spot Results..."
                      empty-filtered-text="No Spot Results..."
                      :filter-function="filterSpots"
                      sticky-header="300px"
                      no-border-collapse
                      selectable
                      show-empty
                      :busy="loadingSpots"
                      primary-key="id"
                      @filtered="onFilter"
                      @sort-changed="onSort"
                      no-select-on-click
                      :sort-null-last="true"
                      bordered
                      sort-icon-left
                    >
                      <template v-slot:table-busy>
                        <div class="text-center my-2">
                          <span
                            class="spinner-border spinner-border-sm mx-auto"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </div>
                      </template>
                      <template v-slot:cell(isSelected)="data">
                        <template v-if="data.rowSelected">
                          <i
                            @click="deselectSpot(data.item.id)"
                            class="align-middle fa-fw fa-check-circle fas text-primary ml-2"
                          ></i>
                          <span class="sr-only">Selected</span>
                        </template>
                        <template v-else>
                          <i
                            @click="selectSpot(data.item.id)"
                            class="align-middle fa-fw fa-check-circle far ml-2"
                          ></i>
                          <span class="sr-only">Not selected</span>
                        </template>
                      </template>
                    </b-table>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-12 mb-3">
                <button
                  type="button"
                  class="btn btn-primary mr-2 my-1"
                  @click="showTemplates"
                >
                  Insert from Template
                </button>
                <button
                  type="button"
                  class="btn btn-primary mr-2 my-1"
                  @click="preview"
                >
                  PREVIEW
                </button>
              </div>
              <div class="col-12">
                <ValidationProvider
                  rules="required|max:1500"
                  name="Park Alert Message"
                  v-slot="{ errors, ariaInput, ariaMsg }"
                >
                  <label
                    @click="$refs.select.focus()"
                    :class="{ error: errors[0] }"
                    for="alertText"
                    >Park Alert Message</label
                  >
                  <ckeditor
                    :config="editorConfig"
                    v-model="parentAlert.message"
                    v-bind="ariaInput"
                    id="alertText"
                  />
                  <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                    <li
                      v-for="(error, index) in errors"
                      :key="index"
                      class="error"
                      v-bind="ariaMsg"
                    >
                      {{ error }}
                    </li>
                  </ul>
                </ValidationProvider>
                <small class="form-text text-muted mb-2">
                  Text to be displayed on the alert.
                </small>
              </div>
              <div class="col-6 mb-3 mt-2">
                <div class="d-flex align-items-center">
                  <ValidationProvider
                    rules="required_if:applyToEmail,false"
                    name="Apply To Website"
                    vid="applyToWeb"
                    v-slot="{ errors, ariaInput, ariaMsg }"
                  >
                    <div>
                      <input
                        type="checkbox"
                        class="mr-2"
                        id="applyToWebsite"
                        v-model="parentAlert.applyToWebsite"
                        v-bind="ariaInput"
                      />
                      <label class="form-check-label" for="applyToWebsite"
                        >Apply To Website</label
                      >
                      <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                        <li
                          v-for="(error, index) in errors"
                          :key="index"
                          class="error"
                          v-bind="ariaMsg"
                        >
                          {{ error }}
                        </li>
                      </ul>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-6 mb-3 mt-2">
                <div class="d-flex align-items-center">
                  <ValidationProvider
                    rules="required_if:applyToWeb,false"
                    name="Apply To Email"
                    vid="applyToEmail"
                    v-slot="{ errors, ariaInput, ariaMsg }"
                  >
                    <div class="mr-2">
                      <input
                        type="checkbox"
                        class="mr-2"
                        id="applyToEmail"
                        v-model="parentAlert.applyToEmail"
                        v-bind="ariaInput"
                      />
                      <label class="form-check-label" for="applyToEmail"
                        >Apply To Email</label
                      >
                      <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                        <li
                          v-for="(error, index) in errors"
                          :key="index"
                          class="error"
                          v-bind="ariaMsg"
                        >
                          {{ error }}
                        </li>
                      </ul>
                    </div>
                  </ValidationProvider>
                </div>
                <div>
                  <b-form-group
                    v-slot="{ ariaDescribedby }"
                    v-if="parentAlert.applyToEmail"
                  >
                    <b-form-radio
                      v-model="parentAlert.showAtTop"
                      :aria-describedby="ariaDescribedby"
                      name="showAtTop"
                      :value="true"
                      >Position at Top of Email Template</b-form-radio
                    >
                    <b-form-radio
                      v-model="parentAlert.showAtTop"
                      :aria-describedby="ariaDescribedby"
                      name="showAtTop"
                      :value="false"
                      >Position at Bottom of Email Template</b-form-radio
                    >
                  </b-form-group>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
        <AdminAlertComments :alert="parentAlert" />
        <AdminAuditHistory
          @getHistory="getHistory"
          :history="history"
          headerTitle="Alert History"
          v-if="parentAlert.id"
        />
        <div class="row">
          <div class="col-12">
            <button
              type="button"
              class="btn btn-primary mr-2 my-1"
              @click="onSubmit"
              :disabled="disableSaveButton"
            >
              SAVE
            </button>
            <button
              type="button"
              class="btn btn-secondary mr-2 my-1"
              @click="resetAllFields"
              v-show="!parentAlert.id"
            >
              CLEAR
            </button>
            <button
              type="button"
              class="btn btn-cancel mr-2 my-1"
              @click="goToAlertSearch"
            >
              CANCEL
            </button>
          </div>
        </div>
      </div>
      <b-modal
        id="templatesModal"
        title="Template Selection"
        ok-title="Cancel"
        ok-variant="secondary"
        ok-only
      >
        <b-table
          ref="templateTable"
          striped
          :fields="templateFields"
          :items="templates"
          :current-page="currentPage"
          :per-page="perPage"
          stacked="md"
          small
          class="mt-4"
          bordered
          sort-icon-left
        >
          <template v-slot:cell(id)="data">
            <button
              class="btn btn-primary btn-sm mr-2"
              @click="selectTemplate(data.item)"
            >
              Select
            </button>
          </template>
        </b-table>
        <div class="d-flex">
          <div class="mr-auto">
            Showing
            {{ perPage * currentPage - perPage + (totalRows > 0 ? 1 : 0) }} to
            {{ perPageDisplay }} of {{ totalRows }} entries
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
          </div>
        </div>
        <div
          class="card-body text-center"
          v-if="templates.length === 0 && !loading"
        >
          <div class="h4 my-3">Currently no templates for the tenant.</div>
        </div>
      </b-modal>
      <AdminHtmlPreviewModal
        title="Alert Preview"
        :content="previewContent"
        ref="previewModal"
      />
    </div>
    <div v-else class="text-center">
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import DateInput from "@/validation/DateInput.vue";
import moment from "moment";
import Multiselect from "vue-multiselect";
import AdminLookupService from "@/services/admin/AdminLookupService.js";
import AdminAlertService from "@/services/admin/AdminAlertService.js";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";
import AdminHtmlPreviewModal from "@/components/admin/AdminHtmlPreviewModal";
import AdminCommunicationTemplateService from "@/services/admin/AdminCommunicationTemplateService.js";
import ckconfig from "@/helpers/ckeditorConfig.js";
import checkPermissionMixin from "@/mixins/PermissionCheckMixin.js";
import AdminAlertComments from "@/components/admin/AdminAlertComments";
import AdminAuditHistory from "@/components/admin/AdminAuditHistory";
import TextInput from "@/validation/TextInput.vue";

export default {
  name: "AdminAlertForm",
  mixins: [checkPermissionMixin],
  components: {
    ValidationObserver,
    ValidationProvider,
    DateInput,
    Multiselect,
    FormErrorAlert,
    AdminHtmlPreviewModal,
    AdminAlertComments,
    AdminAuditHistory,
    TextInput
  },
  props: {
    alert: Object,
    title: String
  },
  data() {
    return {
      previewContent: "",
      parentAlert: {},
      loading: false,
      previouslySelectedParksSpots: [],
      previouslySelectedParksSpotTypes: [],
      selectedParks: [],
      selectedRegions: [],
      selectedSpotTypes: [],
      regions: [],
      parks: [],
      templates: [],
      loadingParks: false,
      spotTypes: [],
      spots: [],
      loadingSpots: false,
      errors: [],
      history: null,
      fields: [
        { key: "isSelected", label: "Selected", sortable: true },
        { key: "locationName", sortable: true },
        { key: "loopName", sortable: true },
        { key: "name", sortable: true },
        {
          key: "spotTypes",
          sortable: true,
          formatter: values => {
            return values.map(x => x.name).join(", ");
          },
          sortByFormatted: true,
          filterByFormatted: true
        }
      ],
      templateFields: [
        {
          key: "name",
          label: "Template Name",
          sortable: true
        },
        {
          key: "id",
          sortable: false,
          label: "Actions/Options",
          class: "actionsColumn"
        }
      ],
      currentPage: 1,
      perPage: 10,
      perPageDisplay: 10,
      pageOptions: [10, 25, 50, 100],
      filter: "",
      editorConfig: ckconfig,
      disableSaveButton: false
    };
  },
  computed: {
    initialDate() {
      if (this.parentAlert.startDate) {
        return moment(this.parentAlert.startDate, "MM-DD-YYYY").format(
          "YYYY-MM-DD"
        );
      } else {
        return "";
      }
    },
    parkNotSelected() {
      return this.selectedParks.length === 0;
    },
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    totalRows() {
      return this.templates.length;
    },
    selectedSpotIds() {
      return this.spots.filter(x => x.isSelected === true).map(y => y.id);
    },
    filteredItemsLength() {
      return this.$refs?.table?.sortedItems?.length ?? 0;
    },
    hasParkSpotPermission() {
      return this.checkPermission("ParkManagementParkAlertsParkSpot");
    },
    hasGlobalPermission() {
      return this.checkPermission("ParkManagementParkAlertsGlobal");
    },
    referenceId() {
      return this.parentAlert.id
        ? `A${(this.parentAlert.id + "").padStart(4, "0")}`
        : "";
    }
  },
  methods: {
    showTemplates() {
      this.$bvModal.show("templatesModal");
    },
    filterSpots(data, filter) {
      if (
        data.isSelected ||
        data.name.toLowerCase().includes(filter.toLowerCase()) ||
        data.loopName.toLowerCase().includes(filter.toLowerCase()) ||
        data.locationName.toLowerCase().includes(filter.toLowerCase()) ||
        data.spotTypes
          .map(x => x.name)
          .join(", ")
          .toLowerCase()
          .includes(filter.toLowerCase())
      ) {
        return true;
      } else {
        return false;
      }
    },
    preview() {
      this.previewContent = this.parentAlert.message;
      this.$refs.previewModal.show();
    },
    selectSpot(id) {
      if (!this.hasParkSpotPermission) return;

      this.spots = this.spots.map(x => {
        return { ...x, isSelected: x.id === id ? true : x.isSelected };
      });
      this.onSort();
    },
    deselectSpot(id) {
      if (!this.hasParkSpotPermission) return;

      this.spots = this.spots.map(x => {
        return { ...x, isSelected: x.id === id ? false : x.isSelected };
      });
      this.onSort();
    },
    onSubmit() {
      this.disableSaveButton = true;
      this.$refs.parkAlertForm.validate().then(success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.parkAlertForm.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.parkAlertForm.refs[errors[0]["key"]].$el?.scrollIntoView(
              {
                behavior: "smooth",
                block: "center"
              }
            );
          }, 100);
          this.disableSaveButton = false;
        } else {
          this.errors = [];
          if (
            this.selectedSpotIds.length !== 0 ||
            this.parentAlert.globalAlert === true
          ) {
            let alertRequest = {
              id: this.parentAlert.id,
              startDate: this.parentAlert.startDate,
              endDate: this.parentAlert.endDate,
              alertTypeId: this.parentAlert.globalAlert === false ? 2 : 1,
              applyToEmail: this.parentAlert.applyToEmail,
              applyToWebsite: this.parentAlert.applyToWebsite,
              showAtTop: this.parentAlert.showAtTop,
              alertSpotIds: this.selectedSpotIds,
              message: this.parentAlert.message,
              comment: this.parentAlert.comment
            };
            this.$emit("submit", alertRequest);
          } else {
            window.scrollTo(0, 0);
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-danger",
              message:
                "You must select at least one spot if not choosing a global alert...",
              layer: "admin"
            });
            this.disableSaveButton = false;
          }
        }
      });
    },
    async getHistory() {
      const alertService = new AdminAlertService(this.tenantId);
      const response = await alertService.getAlertHistory(this.parentAlert.id);
      if (response?.statusCode == "Success") {
        this.history = response.data;
      } else {
        window.scrollTo(0, 0);
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "admin"
        });
      }
    },
    onFilter() {
      // set timeout here specifically so it doesn't run after every character entry on filter
      setTimeout(() => {
        this.$refs.table.sortedItems.forEach((item, index) => {
          if (
            this.spots
              .filter(x => x.isSelected === true)
              .map(y => y.id)
              .includes(item.id)
          ) {
            this.$refs.table.selectRow(index);
          } else if (this.$refs.table.isRowSelected(index)) {
            this.$refs.table.unselectRow(index);
          }
        });
      }, 250);
    },
    onSort() {
      // same here for set timeout, have to give the table a sec to return the sorted items
      setTimeout(() => {
        this.$refs.table.sortedItems.forEach((item, index) => {
          if (
            this.spots
              .filter(x => x.isSelected === true)
              .map(y => y.id)
              .includes(item.id)
          ) {
            this.$refs.table.selectRow(index);
          } else if (this.$refs.table.isRowSelected(index)) {
            this.$refs.table.unselectRow(index);
          }
        });
      }, 100);
    },
    resetAllFields() {
      this.$refs.table.clearSelected();
      this.parentAlert.startDate = this.parentAlert.endDate = this.parentAlert.message = this.filter =
        "";
      this.parentAlert.globalAlert = this.parentAlert.applyToWebsite = this.parentAlert.applyToEmail = false;
      this.previouslySelectedParksSpotTypes = this.previouslySelectedParksSpots = this.selectedParks = this.selectedRegions = this.selectedSpotTypes = this.spots = this.errors = [];
      this.$nextTick(() => this.$refs.parkAlertForm.reset());
    },
    goToAlertSearch() {
      this.resetAllFields();
      this.$router.push("/admin/park-alert-search").catch(() => {});
    },
    minDepartureDate(ymd) {
      if (this.parentAlert.startDate) {
        return moment(this.parentAlert.startDate, "MM-DD-YYYY")
          .subtract(1, "days")
          .isSameOrAfter(ymd, "day");
      } else {
        return false;
      }
    },
    async filterParksAdd(selectedRegion) {
      if (this.parks.length === 0) {
        this.loadingParks = true;
        const parks = await this.getParks();
        this.parks = parks;
        this.selectedParks = [
          ...this.selectedParks,
          ...parks.filter(
            x =>
              x.regionId === selectedRegion.id &&
              !this.selectedParks.map(y => y.id).includes(x.id)
          )
        ];
        this.loadingParks = false;
      } else {
        const parks = this.parks;
        this.selectedParks = [
          ...this.selectedParks,
          ...parks.filter(
            x =>
              x.regionId === selectedRegion.id &&
              !this.selectedParks.map(y => y.id).includes(x.id)
          )
        ];
      }
      await this.openedSpots();
    },
    async filterParksRemove(removedRegion) {
      this.selectedParks = this.selectedParks.filter(
        x => x.regionId !== removedRegion.id
      );
      if (this.selectedParks.length === 0) {
        this.selectedSpotTypes = [];
        this.spots = [];
      }
      await this.openedSpots();
    },
    async selectAllParksAndRegions() {
      if (this.parks.length === 0) {
        this.loadingParks = true;
        const parks = await this.getParks();
        this.parks = parks;
        this.selectedParks = parks;
        this.loadingParks = false;
      } else {
        const parks = this.parks;
        this.selectedParks = parks;
      }
      const regions = this.regions;
      this.selectedRegions = regions;
      await this.openedSpots();
    },
    deselectAllParksAndRegions() {
      this.selectedParks = [];
      this.selectedSpotTypes = [];
      this.selectedRegions = [];
      this.spots = [];
    },
    filterSpotsAdd(addedSpotType) {
      this.$refs.table.sortedItems.forEach((x, index) => {
        if (
          !this.$refs.table.isRowSelected(index) &&
          x.spotTypes.some(y => y.id === addedSpotType.id)
        ) {
          this.spots = this.spots.map(z => {
            return { ...z, isSelected: z.id === x.id ? true : z.isSelected };
          });
        }
      });
      this.onSort();
    },
    filterSpotsRemove(removedSpotType) {
      this.$refs.table.sortedItems.forEach((x, index) => {
        if (
          this.$refs.table.isRowSelected(index) &&
          x.spotTypes.some(y => y.id === removedSpotType.id) &&
          !x.spotTypes
            .filter(n => n.id !== removedSpotType.id)
            .some(a => this.selectedSpotTypes.map(b => b.id).includes(a.id))
        ) {
          this.spots = this.spots.map(z => {
            return { ...z, isSelected: z.id === x.id ? false : z.isSelected };
          });
        }
      });
      if (this.selectedSpotTypes.length === 0) {
        this.spots = this.spots.map(m => {
          return { ...m, isSelected: false };
        });
      }
      this.onSort();
    },
    selectAllSpotsAndSpotTypes() {
      this.spots = this.spots.map(x => {
        return { ...x, isSelected: true };
      });
      this.onSort();
    },
    deselectAllSpotsAndSpotTypes() {
      this.selectedSpotTypes = [];
      this.spots = this.spots.map(x => {
        return { ...x, isSelected: false };
      });
      this.onSort();
    },
    async getParks() {
      const lookupService = new AdminLookupService(this.tenantId);
      const locations = await lookupService.getLocations(this.tenantId);
      return locations.filter(loc =>
        this.checkLocationPermission("ParkManagementParkAlertsParkSpot", loc.id)
      );
    },
    async openedParks() {
      if (this.parks.length === 0) {
        this.loadingParks = true;
        this.parks = await this.getParks();
        this.loadingParks = false;
      }
    },
    async getRegions() {
      const lookupService = new AdminLookupService(this.tenantId);
      const response = await lookupService.getRegions();
      this.regions = response;
    },
    async openedSpotTypes() {
      const oldList = this.previouslySelectedParksSpotTypes;
      const newList = this.selectedParks;
      const same = this.testParkListEquality(oldList, newList);
      if (this.spotTypes.length == 0 || !same) {
        this.previouslySelectedParksSpotTypes = newList;
        this.spotTypes = this.getSpotTypes();
      }
    },
    getSpotTypes() {
      let spotTypes = [];
      this.spots.forEach(x => {
        spotTypes = [...spotTypes, ...x.spotTypes];
      });
      return spotTypes
        .filter(
          (value, index, self) => self.map(x => x.id).indexOf(value.id) == index
        )
        .sort((a, b) =>
          a.name.trim() > b.name.trim()
            ? 1
            : a.name.trim() < b.name.trim()
            ? -1
            : 0
        );
    },
    async openedSpots() {
      const oldList = this.previouslySelectedParksSpots;
      const newList = this.selectedParks;
      const same = this.testParkListEquality(oldList, newList);
      if (this.spots.length == 0 || !same) {
        this.previouslySelectedParksSpots = newList;
        this.selectedSpotTypes = [];
        this.loadingSpots = true;
        let spots = await this.getSpots(this.selectedParks.map(x => x.id));
        this.spots = spots.map(x => {
          return { ...x, isSelected: this.selectedSpotIds.includes(x.id) };
        });
        this.loadingSpots = false;
        this.onSort();
      }
    },
    async getSpots(ids) {
      const lookupService = new AdminLookupService(this.tenantId);
      return await lookupService.getSpotsForLocations(ids);
    },
    testParkListEquality(oldList, newList) {
      if (oldList.length === newList.length) {
        newList.forEach(x => {
          const inc = oldList.some(y => y.id == x.id);
          if (inc === false) {
            return false;
          }
        });
        return true;
      } else {
        return false;
      }
    },
    async initialize() {
      this.loading = true;
      await this.getRegions();
      await this.getTemplates();
      if (!this.parentAlert.id) {
        this.loading = false;
        return;
      }
      this.parentAlert.startDate = moment(
        this.parentAlert.startDate,
        "MM/DD/YYYY"
      ).format("MM/DD/YYYY");
      this.parentAlert.endDate = moment(
        this.parentAlert.endDate,
        "MM/DD/YYYY"
      ).format("MM/DD/YYYY");
      this.parks = await this.getParks();
      this.selectedParks = this.parks.filter(x =>
        this.parentAlert.alertSpots.map(y => y.locationId).includes(x.id)
      );
      let spots = await this.getSpots(this.selectedParks.map(x => x.id));
      this.spots = spots.map(z => {
        return {
          ...z,
          isSelected: this.parentAlert.alertSpots
            .map(y => y.spotId)
            .includes(z.id)
        };
      });
      this.onSort();
      this.loading = false;
    },
    async getTemplates() {
      const service = new AdminCommunicationTemplateService(this.tenantId);
      const response = await service.getTemplates();
      if (response?.statusCode === "Success") {
        this.templates = response.data;
      } else {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "admin"
        });
      }
    },
    selectTemplate(item) {
      this.parentAlert.message = item.content;
      this.$bvModal.hide("templatesModal");
    }
  },
  watch: {
    alert() {
      this.parentAlert = JSON.parse(JSON.stringify(this.alert));
    },
    "parentAlert.globalAlert"() {
      if (this.parentAlert.globalAlert === true) {
        this.selectedRegions = [];
        this.selectedParks = [];
        this.selectedSpotTypes = [];
        this.spots = [];
        this.selectedSpots = [];
      }
    },
    "parentAlert.applyToEmail"() {
      if (
        this.parentAlert.applyToEmail === true &&
        typeof this.parentAlert.showAtTop === "undefined"
      ) {
        this.parentAlert.showAtTop = false;
      }
    }
  },
  async mounted() {
    this.parentAlert = JSON.parse(JSON.stringify(this.alert));
    await this.initialize();
    this.$refs.closureRegions.$refs.search.setAttribute("autocomplete", "off");
    this.$refs.closureParks.$refs.search.setAttribute("autocomplete", "off");
    this.$refs.closureSpotTypes.$refs.search.setAttribute(
      "autocomplete",
      "off"
    );
  }
};
</script>

<style scoped>
.actionsColumn {
  width: 150px !important;
}
.grow {
  flex-grow: 1;
}
</style>

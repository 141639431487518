import AdminAlertApi from "@/generatedapiclients/src/api/AdminAlertApi.js";

export default class AdminAlertService {
  constructor(tenantId) {
    this._tenantId = tenantId;
    this._adminAlertApi = new AdminAlertApi();
  }

  async getGlobalAlerts() {
    const response = await this._adminAlertApi.v1TenantTenantIdAdminAdminAlertGlobalGet(
      this._tenantId
    );

    return response;
  }

  async getAlert(alertId) {
    const response = await this._adminAlertApi.v1TenantTenantIdAdminAdminAlertAlertIdGet(
      alertId,
      this._tenantId
    );
    return response;
  }

  async getAlerts(locationId) {
    const response = await this._adminAlertApi.v1TenantTenantIdAdminAdminAlertLocationLocationIdGet(
      locationId,
      this._tenantId
    );
    return response;
  }

  async deleteAlert(alertId) {
    const response = await this._adminAlertApi.v1TenantTenantIdAdminAdminAlertAlertIdDelete(
      alertId,
      this._tenantId
    );
    return response;
  }

  async addAlert(alertRequest) {
    const response = await this._adminAlertApi.v1TenantTenantIdAdminAdminAlertPost(
      this._tenantId,
      {
        alertRequest
      }
    );
    return response;
  }

  async updateAlert(alertRequest) {
    const response = await this._adminAlertApi.v1TenantTenantIdAdminAdminAlertPut(
      this._tenantId,
      {
        alertRequest
      }
    );
    return response;
  }

  getAlertComments(alertId) {
    return this._adminAlertApi.v1TenantTenantIdAdminAdminAlertAlertIdCommentsGet(
      alertId,
      this._tenantId
    );
  }

  addAlertComment(alertId, comment) {
    return this._adminAlertApi.v1TenantTenantIdAdminAdminAlertAlertIdAddCommentPost(
      alertId,
      this._tenantId,
      {
        comment: comment
      }
    );
  }

  getAlertHistory(alertId) {
    return this._adminAlertApi.v1TenantTenantIdAdminAdminAlertAlertIdHistoryGet(
      alertId,
      this._tenantId
    );
  }
}

/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import AlertHistory from './AlertHistory';

/**
 * The AlertRequest model module.
 * @module model/AlertRequest
 * @version v1
 */
class AlertRequest {
    /**
     * Constructs a new <code>AlertRequest</code>.
     * @alias module:model/AlertRequest
     */
    constructor() { 
        
        AlertRequest.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>AlertRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/AlertRequest} obj Optional instance to populate.
     * @return {module:model/AlertRequest} The populated <code>AlertRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AlertRequest();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('startDate')) {
                obj['startDate'] = ApiClient.convertToType(data['startDate'], 'Date');
            }
            if (data.hasOwnProperty('endDate')) {
                obj['endDate'] = ApiClient.convertToType(data['endDate'], 'Date');
            }
            if (data.hasOwnProperty('alertTypeId')) {
                obj['alertTypeId'] = ApiClient.convertToType(data['alertTypeId'], 'Number');
            }
            if (data.hasOwnProperty('message')) {
                obj['message'] = ApiClient.convertToType(data['message'], 'String');
            }
            if (data.hasOwnProperty('applyToEmail')) {
                obj['applyToEmail'] = ApiClient.convertToType(data['applyToEmail'], 'Boolean');
            }
            if (data.hasOwnProperty('applyToWebsite')) {
                obj['applyToWebsite'] = ApiClient.convertToType(data['applyToWebsite'], 'Boolean');
            }
            if (data.hasOwnProperty('alertSpotIds')) {
                obj['alertSpotIds'] = ApiClient.convertToType(data['alertSpotIds'], ['Number']);
            }
            if (data.hasOwnProperty('history')) {
                obj['history'] = ApiClient.convertToType(data['history'], [AlertHistory]);
            }
            if (data.hasOwnProperty('showAtTop')) {
                obj['showAtTop'] = ApiClient.convertToType(data['showAtTop'], 'Boolean');
            }
            if (data.hasOwnProperty('comment')) {
                obj['comment'] = ApiClient.convertToType(data['comment'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
AlertRequest.prototype['id'] = undefined;

/**
 * @member {Date} startDate
 */
AlertRequest.prototype['startDate'] = undefined;

/**
 * @member {Date} endDate
 */
AlertRequest.prototype['endDate'] = undefined;

/**
 * @member {Number} alertTypeId
 */
AlertRequest.prototype['alertTypeId'] = undefined;

/**
 * @member {String} message
 */
AlertRequest.prototype['message'] = undefined;

/**
 * @member {Boolean} applyToEmail
 */
AlertRequest.prototype['applyToEmail'] = undefined;

/**
 * @member {Boolean} applyToWebsite
 */
AlertRequest.prototype['applyToWebsite'] = undefined;

/**
 * @member {Array.<Number>} alertSpotIds
 */
AlertRequest.prototype['alertSpotIds'] = undefined;

/**
 * @member {Array.<module:model/AlertHistory>} history
 */
AlertRequest.prototype['history'] = undefined;

/**
 * @member {Boolean} showAtTop
 */
AlertRequest.prototype['showAtTop'] = undefined;

/**
 * @member {String} comment
 */
AlertRequest.prototype['comment'] = undefined;






export default AlertRequest;

